/* imports */
@import "/assets/css/webfonts/urwdin.css";
@import "/assets/css/webfonts/pfdin.css";
/* color definitions */
/* body */
body {
  font-family: "urwdin-regular", "Helvetica", "Arial", "sans-serif";
  font-size: 1rem;
  color: #293d4f;
}
/* container width override */
.container {
  max-width: 1440px;
}
/* fonts */
.dinblack {
  font-family: "urwdin-black", "Helvetica", "Arial", "sans-serif";
}
.dinreg,
.preside-ext-featurelist-textcontainer {
  font-family: "urwdin-regular", "Helvetica", "Arial", "sans-serif";
}
.pfdin {
  font-family: "pfdin", "Helvetica", "Arial", "sans-serif";
  letter-spacing: 0.01px;
}
.blockHeadline {
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.blockTeaserTxt {
  font-size: 1.125rem;
  line-height: 1.3rem;
}
.smallTxt {
  font-size: 0.625rem;
  line-height: 0.7rem;
  font-style: italic;
}
.keyFactValue {
  font-size: 4.35rem;
  line-height: 4.4rem;
}
.keyFactSubliner {
  font-size: 0.875rem;
  line-height: 0.9rem;
  font-style: italic;
}
.greyBlueColor {
  color: #293d4f;
}
.greenColor {
  color: #65b32e;
}
.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
/* buttons */
.btn {
  border-radius: 0;
  box-shadow: none !important;
}
.btn-primary,
.btn-primary:active,
.btn-primary:visited {
  background-color: #65b32e !important;
  border: 1px solid #65b32e !important;
  color: #fff !important;
  text-transform: uppercase;
}
.btn-primary:hover {
  background-color: #5b9b1e !important;
  border: 1px solid #5b9b1e !important;
  color: #fff !important;
}
.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary:visited {
  background-color: #fff;
  border: 1px solid #65b32e;
  color: #65b32e;
  text-transform: uppercase;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #65b32e;
  border-color: #65b32e;
}
.btn-secondary,
.btn-secondary:active,
.btn-secondary:visited {
  background-color: #293d4f !important;
  border: 1px solid #293d4f !important;
  color: #fff !important;
  text-transform: uppercase;
}
.btn-secondary:hover {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  color: #293d4f !important;
}
/* navigation */
a.nav-link:link,
a.nav-link:visited {
  color: #293d4f;
  text-decoration: none;
}
a.nav-link:hover {
  text-decoration: underline;
}
a.nav-link.active {
  color: #65b32e;
  text-decoration: underline;
}
/* footer */
.footerContainer {
  background-color: #293d4f;
  color: #eae9e9;
  font-size: 0.9rem;
}
.footerDivider {
  border-top: 3px solid #65b32e;
  width: 10%;
  height: 10px;
}
a.footerLink:link,
a.footerLink:visited,
a.footerLink:active {
  color: #65b32e;
}
a.footerLink:hover {
  color: #65b32e;
  text-decoration: underline;
}
a.jobLink:link,
a.jobLink:visited,
a.jobLink:active {
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #65b32e;
  text-decoration: none;
}
a.jobLink:hover,
a.jobMoreLink:hover,
a.headlineLink:hover {
  text-decoration: underline !important;
}
a.headlineLink:link,
a.headlineLink:visited,
a.headlineLink:active {
  font-size: 2rem;
  line-height: 2rem;
  color: #65b32e;
  text-decoration: none;
}
.jobHeadline {
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #65b32e;
}
a.jobMoreLink:link,
a.jobMoreLink:visited {
  color: #4a4a4a;
  font-size: 0.875rem;
  line-height: 0.9rem;
  text-decoration: none;
}
.paperBack {
  background: url(/assets/img/misc/bg_tapete.png);
}
.jobTitleContainer {
  min-height: 150px;
}
.jobCategory {
  font-size: 0.875rem;
  line-height: 0.9rem;
}
.socialIcons {
  font-size: 4rem;
  line-height: 4rem;
}
/* jobdetail */
h1 {
  color: #65b32e;
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-family: "urwdin-black";
  font-weight: normal;
  font-style: normal;
  text-transform: uppercase;
}
h2.section-content__title {
  color: #293d4f;
  font-family: "urwdin-black";
  font-size: 2rem;
  line-height: 2rem;
  font-weight: normal;
  font-style: normal;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 30px;
}
.content-section ul {
  list-style: none;
  padding-left: 21px;
}
.content-section ul li:before {
  content: ">> ";
  margin-left: -21px;
  color: #65b32e;
  font-weight: bold;
}
.shareJob {
  background-color: #65b32e;
  color: #fff;
}
.shareJob a {
  color: #fff;
}
.widgets {
  padding: 0 0 15px 0;
}
.section.widgets {
  padding: 5px 60px 0 60px;
  margin: 20px 0 0 0;
}
#posted-by {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  line-height: 16px;
}
#posted-by .avatar {
  float: left;
}
#posted-by .job-owner {
  width: 300px;
  float: left;
  padding-left: 15px;
  padding-left: 20px;
  margin: 8px 0 0 0;
}
#posted-by .bottom {
  margin-top: 65px;
}
#apply {
  text-align: center;
}
.btnreg {
  background-color: #65b32e;
  color: #fff;
}
.btnreg:hover {
  background-color: #5b9b1e;
  color: #fff;
}
.btn-applynow {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: inline-block;
  width: auto;
}
.gptwlogo {
  -webkit-box-shadow: 2px 1px 8px 0px #bbb;
  box-shadow: 2px 1px 8px 0px #bbb;
  display: block;
}
.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
@-webkit-keyframes bounce {
  20%,
  53%,
  80%,
  0%,
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}
@keyframes bounce {
  20%,
  53%,
  80%,
  0%,
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}
.imgbox {
  position: relative;
  margin: auto;
  overflow: hidden;
}
.imgbox img {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: auto;
  transform: scale(1);
}
.imgbox:hover img {
  transform: scale(1.1);
}
.pointerClass {
  cursor: pointer;
}
.noPointerClass {
  cursor: default;
}
/* forms */
.formBack {
  background-color: #65b32e;
}
#contactForm input[type="text"],
#contactForm input[type="email"],
#contactForm textarea {
  border: 1px solid #fff;
  background-color: #65b32e;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
}
#contactForm #link {
  display: none;
}
::-webkit-input-placeholder {
  color: #fff;
  font-size: 0.75rem;
}
::placeholder {
  color: #fff;
  font-size: 0.75rem;
}
/* featurelist */
.preside-ext-featurelist-image {
  background-color: #ccc;
}
.preside-ext-featurelist-image .featureImage {
  object-fit: cover;
}
.preside-ext-featurelist-list {
  background-color: #293d4f;
}
.preside-ext-featurelist-textcontainer {
  background-color: #65b32e;
  color: #fff;
}
a.preside-ext-featurelist-link:link,
a.preside-ext-featurelist-link:visited,
a.preside-ext-featurelist-link:active {
  color: #fff;
  text-decoration: underline;
}
.isHidden {
  display: none;
}
.preside-ext-featurelist-link.isActive {
  color: #65b32e !important;
}
/* media queries */
@media (min-width: 992px) {
  h1 {
    color: #65b32e;
    font-size: 3.6rem;
    line-height: 3.8rem;
    font-family: "urwdin-black";
    font-weight: normal;
    font-style: normal;
    text-transform: uppercase;
  }
  .blockHeadline {
    font-size: 2rem;
    line-height: 2rem;
  }
  .jobHeadline {
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: #65b32e;
  }
  .videoThumb {
    max-width: 320px;
  }
  a.jobLink:link,
  a.jobLink:visited {
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: #65b32e;
    text-decoration: none;
  }
}
