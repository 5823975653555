/* urwdin fonts */
@font-face {
    font-family: 'urwdin-regular';
    src: url('urwdin-regular-webfont.woff2') format('woff2'),
         url('urwdin-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal; 
}

@font-face {
    font-family: 'urwdin-black';
    src: url('urwdin-black-webfont.woff2') format('woff2'),
         url('urwdin-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

